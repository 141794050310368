<template>

        <transition name="flip">
            <div v-if="index === increaseIndex" style="margin-top: 30%">
                <h5 class="my-2 text-muted">
                    Step {{index+1}}
                </h5>
                <h4 class="my-4">
                    <span class="text-info " style="font-size: 30px">|</span> {{question.question}}
                </h4>
                <div class="row">
                    <div v-for="(option,i) in question.options" v-bind:key="i">
                        <button :class="{ 'selected' : active_answer == option }" @click="submitAnswer(option,i)"
                                class="mr-2 mb-2" v-if="index!=3"> {{option}}
                        </button>
                        <button :class="{selected: option.active}"  @click="active_answer=i;option.active=!option.active"
                                class="mr-2 mb-2" v-else> {{option.category}}
                        </button>
                    </div>
                </div>
              <div v-if=" $v.active_answer.$error" class="text-danger">
                {{$t('validation.required')}}
              </div>
                <div class="row float-right mt-4">
                    <button @click="decrementIndex" v-if="index > 0 && index <= 3" class="btn btn-outline-success">
                        Previous
                    </button>
                    <button  @click="handlesubmit" id="next" >
                      {{(index == arrayLength-1) ? 'Let\'s Go': 'Next'}}
                    </button>
                </div>
            </div>
        </transition>
</template>

<script>


import {required} from "vuelidate/lib/validators";

export default {
        name: "Questions",
        props: ['question', 'index', 'increaseIndex', 'incrementIndex', 'decrementIndex', 'arrayLength'],
        data() {
            return {
                active_answer: '',


            }
        },
  validations: {
    active_answer: {required},
  },
        methods: {
            submitAnswer(option,i) {
                this.active_answer = option
                this.$emit('submit-answer',i)
            },
          handlesubmit(){

              // stop here if form is invalid
              this.$v.$touch();
              if (this.$v.$invalid) {
                return;
              }

            this.incrementIndex();

          }
        }
    }
</script>

<style scoped>
    #previous {
      border: none;
      box-shadow: 0 14px 28px rgba(0,0,0,0.40), 0 10px 10px rgba(0,0,0,0.30);

    }


    #next {
        color: white;
        background-color: dodgerblue;
      border: none;
      box-shadow: 0 14px 28px rgba(0,0,0,0.40), 0 10px 10px rgba(0,0,0,0.30);

    }

    button,btn{
        padding: 12px;
        margin: 10px;
        font-size: 1em;
        min-width: 100px;
        border-radius: 5px;
        cursor: pointer;
    }

    .flip-enter {

    }

    .flip-enter-active {
        animation: flip-in 0.5s ease-out forwards;
    }

    .flip-leave {


    }

    .flip-leave-active {
        animation: flip-out 0.5s ease-out forwards;
    }

    @keyframes flip-in {
        from {
            transform: rotateY(90deg)
        }
        to {
            transform: rotateY(0deg)
        }

    }

    .selected {
        transform: scale(1.2);
        background-color: #50a5f1;
        color: white;
    }

    @keyframes flip-out {
        from {
            transform: rotateY(0deg)
        }

        to {
            transform: rotateY(90deg)
        }
    }
</style>