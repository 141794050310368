<template>
    <main>
        <div class="row my-5 pt-xl-4 mx-0">
        <div class="col-xl-6 p-0 border-right">

                <div class="back-content pt-5">

                        <div class="row ">
                            <div class="col-xl-8 m-auto text-center" >
                                <img src="../../assets/images/logo-light-icon.png" class="my-4" alt width="200" />

                                        <h4 class="my-4 text-muted">CRISIS</h4>
                                        <h1 class="my-4">{{$t('welcome')}} , {{$store.state.user.username}}</h1>
                                        <h4 class="my-4 text-muted">{{$t('welcome_p')}}</h4>

                         </div>

                    </div>

                </div>
            </div>
            <div class="col-xl-6">
                <div class="row m-0">
                    <div class="col-xl-8 m-auto" >

                <questions
                        v-for="(question, index) in questions" v-bind:key="index"
                        :question="question"
                        :index="index"
                        :increase-index="increaseIndex"
                        v-on:submit-answer="submitAnswer"
                        :increment-index="incrementIndex"
                        :decrement-index="decrementIndex"
                        :array-length="arrayLength">
                </questions>
                    </div>
                </div>
            </div>
        </div>



    </main>
</template>

<script>
    import helper from "@/assets/helpers/helper";
    import Questions from "../../components/Questions";
    export default {
        name: "FirstInfos",
        components: {Questions},
        data() {
            return {
                increaseIndex: 0,
                questions: [
                    {
                        id:1,
                        question: "I am here to",
                        options: ['Prepare for Job Interviews', 'Learn & Compete with Others'],

                    },

                    {
                        id:2,
                        question: "I am a",
                        options: ['Student', 'Working Professional'],
                    },

                    {
                        id:3,
                        question: "I have been in the field of cybersecurity or blockchain for",
                        options: ['< 1 years', '1 - 5 years', '+  5 years'],

                    },

                    {
                        id:4,
                        question: "What are your favorite categories ?",
                        options: [],

                    }
                ],
                answers:{
                    step1:'',
                    step2:'',
                    step3:'',
                },
            }
        },
        watch:{
            increaseIndex:function () {
                if(this.increaseIndex == 4)  {
                  this.setAnswers()

                  this.setPreferences()
                }

            }
        },
        created(){
            this.getCategories()
        },
        methods:{
            async getCategories(){
                let options = []
                let categories = await helper.getAll('categories');
                categories.forEach(element => {
                    let a = {
                        id:element.id,
                        active:false,
                        category:element.name,
                    }
                    options.push(a);
                });
                this.questions[3].options = options
            },
            incrementIndex(){
                return (this.increaseIndex++)
            },
            decrementIndex(){
                return (this.increaseIndex--)
            },
            submitAnswer(option){
                if(this.increaseIndex == 0) this.answers.step1 = option
                if(this.increaseIndex == 1) this.answers.step2 = option
                if(this.increaseIndex == 2)  this.answers.step3 = option

            },

            async setPreferences(){
             let re =   await helper.postData('me/preferences',{'categories_id':this.favorite_categories})
                if(re) {
                    this.$store.commit('interceptError',false)
                    this.$router.push({'name':'Dashboard'})
                }
            },
            async setAnswers(){
                await helper.postData('me/questions',this.answers)

            }


        },

        computed: {
            arrayLength(){
                return this.questions.length
            },

            favorite_categories: function () {
                let cats = []

                 this.questions[this.increaseIndex-1].options.forEach(element => {
                   if(element.active) cats.push(element.id);
                });
                return cats;
            }



        }
    }
</script>

<style scoped>


    .img{
        width: 100%;
        height: 100%;
    }
    .back-content {

        background: rgb(34, 39, 54); /* Fallback color */
        background: rgba(34, 39, 54, 0.5); /* Black background with 0.5 opacity */
        color: #f1f1f1;
        width: 100%;
        height: 100%;

    }
    @media screen and (max-width: 700px) {

     img{
     width:120px!important;
     }

    }

</style>